<template>
  <div>
    <el-table
      v-loading="loading"
      height="67vh"
      :stripe="true"
      :data="lists">
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        width="200"
        label="通知主题"
        prop="title"
      >
      </el-table-column>
      <el-table-column
        label="发送方式"
        prop="sendType"
      >
      </el-table-column>
      <el-table-column
        label="已发送人数(人)"
        width="160"
        prop="sendPp"
      >

      </el-table-column>

        <el-table-column
        label="点开率/唤回率"
        width="160"
        prop="bfb"
      >

      </el-table-column>

      <!-- <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >

      </el-table-column> -->
    </el-table>
      <page
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
  </div>
</template>

<script>
import MsgService from '@admin/services/MsgService'
// import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'MsgCount',
  data () {
    return {
      function: function (param) {
        return MsgService.allMsg(param)
      },
      delete: function (param) {
        return MsgService.del(param)
      }
    }
  },

  components: {
    Page
  },
  mixins: [pagination],
  methods: {
    AddMsg () {
      this.$router.replace({ path: '/msg-temp/create' })
    },
    handleModelEdit (model) {
      this.$router.push({ path: '/msg-temp/update', query: { id: model.id } })
    }
  }

}

</script>
<style lang='scss' scoped>
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right !important;
        margin: 0;
    }
}

</style>
